import { useEffect, useState } from "react";

const useQueryParams = () => {
    const [queryParams, setQueryParams] = useState({});

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        const entriesIterator = searchParams.entries();
        const entries: Array<[string, string]> = Array.from(entriesIterator);
        const params = Object.fromEntries(entries);

        setQueryParams(params);
    }, []);

    return queryParams;
};

export default useQueryParams;
