import styles from "./InputText.module.css";

interface InputTextProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    icon: any,
    valid: boolean
}

const InputText: React.FC<InputTextProps> = (props) => {
    return (
        <div className={styles.inputTextContainer}>
            <img src={props.icon} alt="Icone usuario" />
            <input {...props} type="text" className={styles.inputText} style={{
                outline: props.valid ? 'auto' : 'initial'
            }} />
        </div>
    );
};

export default InputText;
