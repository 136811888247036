import styles from "./Button.module.css";

type NativeInterface = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

interface ButtonInterface extends NativeInterface {
    Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}

const Button: React.FC<ButtonInterface> = (props) => {
    return (
        <button {...props} className={`${styles.button} ${props.disabled ? styles.disabled : ''}`}>
            {props.value}
            <props.Icon />
        </button>
    );
};

export default Button;
