import { useContext, useState } from "react";

import styles from "./Dados.module.css";

import { ReactComponent as rowIcon } from "../../assets/arrow.svg";
import lockIcon from "../../assets/lock.svg";
import simbolo from "../../assets/simbolo-plin.png";
import userIcon from "../../assets/user.svg";

import Button from "../../components/button/Button";
import InputText from "../../components/inputText/InputText";
import APIContext from "../../contexts/API";

const Dados = () => {
    const { user, setUser, pass, setPass, update } = useContext(APIContext);

    const [displayForm, setDisplayForm] = useState(true);
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        setLoading(true);
        update()
            .then(() => {
                setSuccess(true);
                setMessage("Muito obrigado por ter terminado o seu cadastro!");
                setDisplayForm(false);
            })
            .catch(() => {
                setSuccess(false);
                setDisplayForm(false);
                setMessage(
                    "Houve um erro ao tentar enviar os seus dados, por favor entre em contato conosco para que possamos te ajudar!"
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                <form
                    className={styles.form}
                    onSubmit={(evt) => evt.preventDefault()}
                    style={{ display: displayForm ? "flex" : "none" }}
                >
                    <img src={simbolo} alt="Simbolo sol" />
                    <span>
                        Insira seus dados que entraremos em contato o mais breve
                        possível
                    </span>
                    <InputText
                        icon={userIcon}
                        placeholder="Seu login da conta COPEL"
                        value={user}
                        onChange={(evt) => setUser(evt.target.value)}
                        valid={!!user}
                    />
                    <InputText
                        icon={lockIcon}
                        placeholder="Senha do login da COPEL"
                        value={pass}
                        onChange={(evt) => setPass(evt.target.value)}
                        valid={!!pass}
                    />
                    <span>
                        Saiba mais sobre{" "}
                        <a href="#">
                            porque nós precisamos do seu login na COPEL
                        </a>
                    </span>
                    <Button
                        value={loading ? "Enviando..." : "Enviar"}
                        Icon={rowIcon}
                        disabled={!user || !pass || loading}
                        onClick={handleSubmit}
                    />
                </form>
                <div
                    className={`${styles.messageContainer} ${
                        success ? styles.messageSuccess : ''
                    }`}
                    style={{
                        display: displayForm ? 'none' : 'flex'
                    }}
                >
                    <span>{message}</span>
                </div>
            </div>
        </div>
    );
};

export default Dados;
