import React, { createContext, useEffect, useState } from "react";
import useQueryParams from "../hooks/useQueryParams";
import APIGateway from "./APIGateway";

export interface APIContextParams {
    user: string;
    setUser: (user: string) => void;
    pass: string;
    setPass: (pass: string) => void;
    update: () => Promise<void>;
}

const APIContext = createContext<APIContextParams>({
    user: "",
    setUser: (user: string) => {},
    pass: "",
    setPass: (user: string) => {},
    update: async () => {},
});

export const useAPIContext = () => React.useContext(APIContext);

interface APIProviderParams {
    children: React.ReactNode;
    APIGateway: APIGateway;
}

export const APIProvider: React.FC<APIProviderParams> = ({
    children,
    APIGateway,
}) => {
    const queryParams: any = useQueryParams();

    const [id, setId] = useState("");
    const [user, setUser] = useState("");
    const [pass, setPass] = useState("");

    useEffect(() => {
        const idFromQuery = String(queryParams.id || '');
        setId(idFromQuery);
    }, [queryParams]);

    const update = async () => {
        if (!id) {
            window.location.href = 'https://plinenergia.com.br/';
        }
        return APIGateway.updatePessoaFisica(id, {
            loginDistribuidora: user,
            senhaDistribuidora: pass,
        });
    };

    return (
        <APIContext.Provider
            value={{
                user,
                pass,
                setPass,
                setUser,
                update,
            }}
        >
            {children}
        </APIContext.Provider>
    );
};
export default APIContext;
