import axios from "axios";

import APIGateway, { UpdatePessoaFisicaDto } from "./APIGateway";

const BASE_URL = "https://plin-cadastro-pf-ae8d63399be0.herokuapp.com";
const TOKEN_API =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjZjOTRiMjg3LTIzMDUtNGU3NS1iMjA1LWZiM2Y0MmVjZDJmNiIsImlhdCI6MTcwMjQ4MDA0MX0.8TVVlDTOxx2RmVge7Ozk3P0IerG0aVUDi-5oSv8fDbQ";

export default class APIAxiosGateway implements APIGateway {
    updatePessoaFisica(
        id: string,
        payload: UpdatePessoaFisicaDto
    ): Promise<void> {
        return axios.patch(
            `/pessoa-fisica/credenciais-distribuidora/${id}`,
            { ...payload },
            {
                baseURL: BASE_URL,
                headers: {
                    Authorization: `Bearer ${TOKEN_API}`,
                },
            }
        );
    }
}
