import styles from "./App.module.css";

import Menu from "./components/menu/Menu";
import { APIProvider } from "./contexts/API";
import APIAxiosGateway from "./contexts/APIAxiosGateway";
import Dados from "./sections/dados/Dados";

const App = () => {
    const apiGateway = new APIAxiosGateway();
    return (
        <APIProvider APIGateway={apiGateway}>
            <div className={styles.container}>
                <Menu />
                <Dados />
            </div>
        </APIProvider>
    );
};

export default App;
