import styles from './Menu.module.css';

import marca from "../../assets/marca-plin.png";

const Menu = () => {
    return <div className={styles.menu}>
        <img src={marca} alt="Logotipo Plin Energia" />
    </div>;
}

export default Menu;